@import '../../../node_modules/bootstrap/scss/bootstrap.scss';
@import '../../../node_modules/ngx-toastr/toastr-bs5-alert';

.toast:not(.show) {
  display: block;
}

// Angular Bootstrap popover & tooltip fixes, see
// https://github.com/valor-software/ngx-bootstrap/issues/6492.

.popover {
  position: absolute;
  top: 0;
  left: 0 #{'/* rtl:ignore */'};
}

.popover-arrow {
  position: absolute;
}

.tooltip {
  position: absolute;
}

.tooltip-arrow {
  position: absolute;
}
